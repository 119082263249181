import React, { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import "../../assets/css/blog.css";
import BlogCard from "./Cards";
import { blogCard } from "../services/services";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchBlogs = async (currentPage) => {
    setLoading(true);
    try {
      const response = await blogCard(currentPage);
      const data = response.data;

      if (data?.blogs) {
        setBlogs((prev) => {
          const uniqueBlogs = new Map();
          [...prev, ...data.blogs].forEach((blog) => {
            uniqueBlogs.set(blog.uuid, blog); // Use uuid as the unique key
          });
          return Array.from(uniqueBlogs.values()); // Convert back to an array
        });
        setHasMore(data.hasMore); // Update whether there are more blogs to load
      }
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch initial blogs
  useEffect(() => {
    fetchBlogs(page);
  }, [page]);

  return (
    <Box mt={13}>
      <Box className="banner">
        <Typography variant="h2">Blogs</Typography>
      </Box>

      <Box className="card-container">
        {blogs.map((blog) => (
          <BlogCard key={blog.uuid} data={blog} />
        ))}
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      {!loading && hasMore && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPage((prev) => prev + 1)}
          >
            View More
          </Button>
        </Box>
      )}

      {!hasMore && !loading && (
        <Box textAlign="center" mt={2}>
          <Typography variant="body1">No more blogs to load.</Typography>
        </Box>
      )}
    </Box>
  );
}

export default Blogs;
