import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

//utility
import Navbar from "./components/utils/Navbar";
import Footer from "./components/utils/Footer";
import SnackBar from "./components/utils/SnackBar";
import Verification from "./components/utils/Verification";

// components
import Home from "./components/home/Home";
import Login from "./components/log_signup/Login";
import SignUp from "./components/log_signup/Signup";
import Courses from "./components/courses/CourseList";
import Details from "./components/courses/Details";
import BlogDetails from "./components/blog/Details";
import AboutUs from "./components/aboutUs/AboutUs";
import ContactUs from "./components/contact/ContactUs";
import Career from "./components/career/Career";
import Edubuk from "./components/edubuk/Edubuk";
import Question from "./components/studyplaner/Question";

// mui
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";

// Google Analytics
import GA from "react-ga4";
import ForgetPassword from "./components/log_signup/ForgetPassword";
import ResetPassword from "./components/log_signup/Reset";
import LogIn from "./components/edubuk/LogIn";
import Profile from "./components/profile/Profile";
import Congratulations from "./components/profile/CongressPage";
import MIIT, { AnimatedBeamDemo } from "./components/edubuk/MIIT";
import CareerMapping from "./components/edubuk/CareerMapping";
import { CongressInterest } from "./components/profile/CongressInterest";
import FloatingSearchBar from "./components/utils/FloatingSearchBar";
import Blogs from "./components/blog/Blogs";
import ListBlogsSearch from "./components/blog/ListBlogsSearch";

const Tracking_ID = "G-BH0Z42715R";
GA.initialize(Tracking_ID);

function App() {
  const light = createTheme({
    palette: {
      primary: {
        main: "#f15a29",
      },
      secondary: {
        main: "#ef3c3c",
      },
    },
  });

  return (
    <ThemeProvider theme={light}>
      <BrowserRouter>
        <SnackBar />
        <Navigation />
      </BrowserRouter>
    </ThemeProvider>
  );
}

function Navigation() {
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    tracker("Visit", location.pathname, location.pathname, location.pathname);
    GA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Page",
    });
  }, [location.pathname]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#f25a29", // Your primary color
      },
    },
  });

  return (
    <Box mt={8}>
      <ThemeProvider theme={theme}>
        <Navbar history={history} />
        <Routes>
          <Route exact path="/" element={<Home history={history} />}></Route>
          <Route
            exact
            path="/login"
            element={<Login history={history} />}
          ></Route>
          <Route
            exact
            path="/reset"
            element={<ForgetPassword history={history} />}
          ></Route>
          <Route
            exact
            path="/resetPassword/:token"
            element={<ResetPassword history={history} />}
          ></Route>
          <Route
            exact
            path="/signup"
            element={<SignUp history={history} />}
          ></Route>
          <Route
            exact
            path="/eduLogIn"
            element={<LogIn history={history} />}
          ></Route>
          <Route
            exact
            path="/MIIT"
            element={<MIIT history={history} />}
          ></Route>
 

          {/* // listing */}
          <Route
            exact
            path="/listing"
            element={<Courses history={history} />}
          ></Route>
          <Route
            exact
            path="/listing/:search"
            element={<Courses history={history} />}
          ></Route>
          <Route
            exact
            path="/listing/:option/:status"
            element={<Courses history={history} />}
          ></Route>
          {/* // details  */}
          <Route
            path="/courseDetails/:provider/:title"
            element={<Details></Details>}
          ></Route>
          <Route
            path="/courseDetails/:provider/:index_no/:title"
            element={<Details></Details>}
          ></Route>
          {/* // about  */}
          <Route
            path="/about"
            element={<AboutUs history={history}></AboutUs>}
          ></Route>
          {/* // contact us  */}
          <Route
            path="/contact"
            element={<ContactUs history={history}></ContactUs>}
          ></Route>
          {/* // career us  */}
          <Route
            path="/career"
            element={<Career history={history}></Career>}
          ></Route>
          {/* // Edubuk  */}
          <Route
            path="/edubuk"
            element={<Edubuk history={history}></Edubuk>}
          ></Route>
          <Route
            path="/careerMap/:id"
            element={<CareerMapping history={history}></CareerMapping>}
          ></Route>
          <Route
            path="/profile"
            element={<Profile history={history} />}
          ></Route>
          <Route
            path="/congress"
            element={<Congratulations history={history} />}
          ></Route>
          <Route
            path="/congress_interest"
            element={<CongressInterest history={history} />}
          ></Route>
          {/* // Questions  */}
          <Route
            path="/question"
            element={<Question history={history}></Question>}
          ></Route>
          {/* // Verification  */}
          <Route
            path="/verified/:email/:password"
            element={<Verification history={history}></Verification>}
          ></Route>
          {/* // Blog  */}
          <Route
            exact
            path="/blogs"
            element={<Blogs history={history} />}
          ></Route>
          <Route
            exact
            path="/blog-details/:slug"
            element={<BlogDetails history={history} />}
          ></Route>
          <Route
            exact
            path="/blogs-by-tag/:tag/:uuid"
            element={<ListBlogsSearch history={history} />}
          ></Route>
          <Route
            exact
            path="/blogs-by-tag/:tag"
            element={<ListBlogsSearch history={history} />}
          ></Route>
          {/* // All end  */}
          <Route path="/*" element={<Home history={history}></Home>}></Route>
        </Routes>
        <FloatingSearchBar />
        <Footer history={history} />
      </ThemeProvider>
    </Box>
  );
}

export function tracker(category, action, event_label = " ", value = " ") {
  // console.log(category,action,event_label,value)
  GA.event({
    category,
    action,
    label: event_label,
    value,
  });
}

export default App;
