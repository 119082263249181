import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getBlogDetails,
  getRecentPost,
  getTagName,
} from "../services/services";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/action/action";
import "../../assets/css/blog.css";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { API, frontEnd } from "../../config";
import defaultBlogImg from "../../assets/images/defaultBlog.png";

function Details() {
  let dispatch = useDispatch();
  let { slug } = useParams();
  let [data, setData] = useState(null);
  let [TOC, setTOC] = useState({
    recent_post: [],
    tags: [],
  });
  const fullPath = window.location.href;

  console.log(fullPath);

  useEffect(() => {
    fetchBlog();
    fetchRecentPostAndTags();
  }, [slug]);

  async function fetchRecentPostAndTags() {
    try {
      let recentPost = await getRecentPost(5);
      let tags = await getTagName();

      if (recentPost.status !== 200 || tags.status !== 200)
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Recent Post and Tag taking time to load.",
          })
        );

      if (recentPost?.data)
        setTOC((old) => ({
          ...old,
          recent_post: [...recentPost.data.post],
        }));
      if (tags?.data)
        setTOC((old) => ({
          ...old,
          tags: [...tags.data.list],
        }));
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !!!",
        })
      );
    }
  }
  async function fetchBlog() {
    try {
      let response = await getBlogDetails(slug);
      if (response.status === 200) {
        let { blog } = response?.data;
        setData({ ...blog });
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !!!",
        })
      );
    }
  }
  function formatDate(date) {
    date = new Date(date);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);

    return formattedDate;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content={data?.title || "Blog"} />
        <meta
          name="description"
          content={data?.description || "Blog details"}
        />
        <meta name="author" content={data?.author || "Anonymous"} />
        <meta property="og:title" content={data?.title || "Blog"} />
        <meta property="og:description" content={data?.description || ""} />
        <meta
          property="og:image"
          content={data?.card_image || defaultBlogImg}
        />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:url" content={fullPath} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Classbzaar" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={data?.title || "Blog"} />
        <meta name="twitter:description" content={data?.description || ""} />
        <title>{data?.title || "Blog"}</title>
        <link rel="canonical" href={fullPath} />
      </Helmet>

      <Box mt={15}>
        {data && (
          <Grid container className="blog-main-container">
            <Grid item xs={12} md={8} className="blog-content-container">
              <Box className="blogs-banner">
                <ImageWithFallback src={data.card_image} alt={"blogsImage"} />
              </Box>
              <Box className="blogs-meta">
                <Typography variant="h4" fontWeight={500}>
                  {data.title}
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <svg
                      width={20}
                      height={20}
                      fill="#f25a29"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <title>account-circle</title>
                      <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                    </svg>
                    <Typography variant="">{data.author}</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <svg
                      width={20}
                      height={20}
                      fill="#f25a29"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <title>calendar-range</title>
                      <path d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z" />
                    </svg>
                    <Typography variant="">
                      {formatDate(data.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>{ReactHtmlParser(data.content)}</Box>
            </Grid>
            <Grid
              item
              xs={12}
              className="TOC"
              md={4}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
            >
              {/* // Recent Blogs Links */}
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography variant="h6">Recent Posts</Typography>
                {TOC.recent_post &&
                  TOC.recent_post.map((post) => (
                    <Box
                      className="blog-recent-link"
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box>
                        <svg
                          width={28}
                          height={28}
                          fill="#f25a29"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <title>post-outline</title>
                          <path d="M19 5V19H5V5H19M21 3H3V21H21V3M17 17H7V16H17V17M17 15H7V14H17V15M17 12H7V7H17V12Z" />
                        </svg>
                      </Box>
                      <Typography
                        variant="h6"
                        className="blog-recent-link"
                        component={Link}
                        to={`${frontEnd}/blog-details/${post.slug}`}
                      >
                        {post.title}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              {/* // Tags Link */}
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography variant="h6">Popular Tags</Typography>
                <Box display={"flex"} gap={2} flexWrap={"wrap"}>
                  {TOC.tags &&
                    TOC.tags
                      .filter((tag) =>
                        data.tags.includes(`${tag.tag_name} Tag`)
                      )
                      .map((tag) => (
                        <Button variant="outlined" className="tag_button">
                          <Box pr={1}>
                            <svg
                              width={20}
                              height={20}
                              fill="#f25a29"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <title>tag</title>
                              <path d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                            </svg>
                          </Box>
                          <Typography
                            variant="body1"
                            component={Link}
                            to={`${frontEnd}/blogs-by-tag/${formatString(
                              tag.tag_name
                            )}`}
                          >
                            {tag.tag_name}
                          </Typography>
                        </Button>
                      ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

const ImageWithFallback = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = defaultBlogImg;
      }}
    />
  );
};

const formatString = (str) => {
  return str.trim().replace(/\s+/g, "-");
};

export default Details;
