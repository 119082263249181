import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Button,
  Fade,
  Modal,
  Backdrop,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Drawer,
  IconButton,
} from "@mui/material";

// image
import pink from "../../assets/images/topVector.png";
import pink2 from "../../assets/images/pink2.png";
import blue from "../../assets/images/blue.png";
import yellow from "../../assets/images/yellow.png";
import timer from "../../assets/images/timer.png";
import level from "../../assets/images/level.png";
import content from "../../assets/images/content.png";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

// css
import "../../assets/css/courseList.css";
import "../../assets/css/utility.css";
import { card_track, getCourses } from "../services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Close, Filter1Outlined } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

// GA
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";

const CourseList = (props) => {
  useTrackTime();
  const controllerRef = useRef(new AbortController());
  // var url = `${API}/api/v2/courses/?q=${query}&filter=${parsedFilter}&subjects=${subjects}&provider=${this.state.providers}&feeFilter=${feeFilter}&startDateFilter=${startDateFilter}`;
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false); // show no more course is there

  const { search, option } = useParams();

  const encodedSearchQuery = encodeURI(search);

  // state of the everything
  const [meta, setMeta] = useState({
    hasMore: true,
    fireFilter: option ? true : false,
    offset: {
      Udemy: 0,
      edX: 0,
      "Future-Learn": 0,
      Coursera: 0,
    },
  });

  useEffect(() => {
    setMeta((old) => ({
      ...old,
      fireFilter: true,
      offset: {
        Udemy: 0,
        edX: 0,
        "Future-Learn": 0,
        Coursera: 0,
      },
    }));
    setFilter((old) => ({
      open: false,
      search: encodedSearchQuery,
      [option]: true,
    }));
  }, [window.location.pathname,search]);

  const { auth } = useSelector((state) => state);

  const [filter, setFilter] = useState({
    open: false,
    search: encodedSearchQuery,
    [option]: true,
  });

  useEffect(() => {
    fetchData();
  }, [meta.fireFilter]);

  async function fetchData() {
    try {
      const controller = new AbortController();
      controllerRef.current = controller;
      let lastData = data;
      setShow(false);
      if (meta.fireFilter) {
        lastData = [];
        setData([]);
      }
      // console.log("filter >>>", filter)
      let response = await getCourses(
        {
          filter: filter,
          offset: meta.offset,
        },
        controller.signal
      );

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setMeta((old) => ({ hasMore: true, offset: response.data.offset }));
          let map = new Map();
          // old data
          lastData.map((row) => map.set(row.uuid, row));
          // new data
          response.data.data.map((row) => map.set(row.uuid, row));
          setData([...map.values()]);
        } else {
          if (data.length === 0) setShow(true);
          setMeta((old) => ({ hasMore: false, offset: response.data.offset }));
        }
      }
    } catch (err) {
      // console.log("err>>", err);
    }
  }

  function handleFilter() {
    tracker("Course Action", "Filter scrolled", "filter");
    setFilter((old) => ({ ...old, open: !old.open }));
  }

  return (
    <>
      {/* helmet tag  */}
      <Helmet>
        <title>Online Course Listings | Class Bazaar</title>
        {/* <link rel="canonical" href="" /> */}
        <link rel="canonical" href="https://www.classbazaar.com/listing" />

        <meta name="keywords" content="online courses, Online Course List" />
        <meta
          name="description"
          content="Class Bazaar offers a comprehensive listing of online courses to suit all interests and career goals. Find the right course for you and start your educational journey with us today."
        />
      </Helmet>
      {/* helmet tag ends  */}
      {/* main Section  */}
      <Grid container mt={5} className="cardContainer">
        <Grid item xs={6}>
          <Typography component={"h1"} className="courseListHeading b6">
            Top Courses
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleFilter}
            size="small"
            variant="contained"
            className="filterBtn"
            startIcon={<FilterAltIcon />}
          >
            <b>Filter</b>
          </Button>
        </Grid>
        {/* // ye karna pada because MUI mt support nhi kar rha he */}

        {/* filter model box */}
        <Grid
          item
          xs={12}
          className={filter.open ? "filterBox expand" : "filterBox shirnk"}
        >
          <Filter
            controller={controllerRef}
            filter={filter}
            setFilter={setFilter}
            meta={meta}
            setMeta={setMeta}
          />
        </Grid>

        {/* filter model box ends */}

        <Grid item xs={12}>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={meta.hasMore}
            // style={styleStroller}
            loader={
              <center style={{ padding: "10px" }}>
                <CircularProgress />
              </center>
            }
          >
            <Box className="cardContainer_inner">
              {data && data.map((row) => <Card row={row} auth={auth} />)}
            </Box>
          </InfiniteScroll>
        </Grid>
        {data.length === 0 && show ? (
          <Box
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="h4">&#128531;</Typography>
            <Typography variant="h5">Sorry no courses found !</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Grid>
      {/* main Section ends */}
      <FilterOfSmallSize
        filter={filter}
        setFilter={setFilter}
        meta={meta}
        setMeta={setMeta}
      />
    </>
  );
};
function removeSpecialCharacters(text) {
  // Replace any character that is not a letter, number, or whitespace
  return text.replace(/[^a-zA-Z0-9\s]/g, "-").toLowerCase();
}

// card Template
function Card({ row, auth }) {
  const vector = [pink2, blue, yellow];

  function handleTracker(course_title, uuid) {
    tracker("Course Action", "Course card clicked", row.title, "card");
    if (auth.isAuth)
      card_track({
        user_name: auth.name,
        email: auth.email,
        course_title,
        uuid,
      });
    else
      card_track({
        user_name: "Not Logged In",
        email: "Not Logged In",
        course_title,
        uuid,
      });
  }
  return (
    <Box
      className="card_otter"
      onClick={() => handleTracker(row.title, row.uuid)}
      component={Link}
      to={`/courseDetails/${row.provider}/${
        row.index_no
      }/${removeSpecialCharacters(row.title.replace(/ /g, "-"))}`}
    >
      <img
        src={vector[parseInt(Math.random() * (2 - 0 + 1) + 0)]}
        className="cardImage"
        alt="design"
      />
      <Grid container className="course_card">
        <Grid item xs={12} className="card_title allPad">
          <Typography variant="h5" className="b6">
            {row.title}
          </Typography>
        </Grid>
        <Grid item xs={12} className="allPad">
          <Grid container p={1} className="secSec">
            <Grid item xs={4} className="infoIcon">
              <img src={timer} alt="timer" />
              <Typography variant="h5">
                {row.start_date ? row.start_date.split("T")[0] : "flexible"}
              </Typography>
            </Grid>
            <Grid item xs={4} className="infoIcon">
              <img src={level} alt="level" />
              <Typography variant="h5">Any</Typography>
            </Grid>
            <Grid item xs={4} className="infoIcon">
              <img src={content} alt="duration" />
              <Typography variant="h5">{row.provider}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="allPad">
          <Grid container className="thirdSec">
            <Grid item xs={8} className="universityClip">
              <Typography variant="h5">
                {row.university !== "" ? row.university : row.provider}
              </Typography>
            </Grid>
            <Grid item xs={4} className="price">
              <Typography variant="h5">
                <b>
                  {row.price
                    ? parseInt(row.price).toLocaleString("us-Rs", {
                        style: "currency",
                        currency: "INR",
                      })
                    : "Free"}
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

// filter Box
function Filter({ filter, setFilter, meta, setMeta, controller }) {
  function handleFilter(e) {
    controller.current.abort();
    tracker("Course Action", "Selecting filter", e.target.name);
    setFilter((old) => ({ ...old, [e.target.name]: e.target.checked }));
  }

  function fireFilter(e) {
    setMeta((old) => ({
      ...old,
      fireFilter: !old.fireFilter,
      hasMore: true,
      offset: {
        Udemy: 0,
        edX: 0,
        "Future-Learn": 0,
        Coursera: 0,
      },
    }));
    setFilter((old) => ({ ...old, open: false, search: "" }));
  }

  return (
    <Box className="filterInner">
      {/* <img src={pink} className="cardImage" alt="design" /> */}
      <Grid container>
        {/* // Provider */}
        <Grid xs={3}>
          <Typography className="filterTitle">Provider</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Udemy || false}
                  size={"small"}
                  color="primary"
                  name="Udemy"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Udemy"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.edX}
                  size={"small"}
                  color="primary"
                  name="edX"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="edX"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter["Future-Learn"]}
                  size={"small"}
                  name="Future-Learn"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Future Learn"
            />
            {/* <FormControlLabel
            className="filterLable"
            control={
              <Checkbox
                checked={filter.Coursera}
                size={"small"}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon />}
                name="Coursera"
                checkedIcon={<AddBoxIcon />}
                onChange={handleFilter}
              />
            }
            label="Coursera"
          /> */}
          </FormGroup>
        </Grid>
        {/* Fees  */}
        <Grid xs={3}>
          <Typography className="filterTitle">Fees</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Free}
                  size={"small"}
                  color="primary"
                  name="Free"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Free"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Paid}
                  size={"small"}
                  color="primary"
                  name="Paid"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Paid"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Subscription}
                  size={"small"}
                  name="Subscription"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Provider Subscription"
            />
          </FormGroup>
        </Grid>
        {/* Start Date  */}
        <Grid xs={3}>
          <Typography className="filterTitle">Start Date</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.withIn30}
                  size={"small"}
                  color="primary"
                  name="withIn30"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Start With In 30 Days"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.after30}
                  size={"small"}
                  color="primary"
                  name="after30"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Start After 30 Days"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Flexible}
                  size={"small"}
                  name="Flexible"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Flexible"
            />
          </FormGroup>
        </Grid>
        {/* Subjects  */}
        <Grid xs={3}>
          <Typography className="filterTitle">Subjects</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Computer_Science}
                  size={"small"}
                  color="primary"
                  name="Computer_Science"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Computer Science"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Arts_And_Design}
                  size={"small"}
                  color="primary"
                  name="Arts_And_Design"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Arts & Design"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Business}
                  size={"small"}
                  color="primary"
                  name="Business"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Business"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Data_Science}
                  size={"small"}
                  color="primary"
                  name="Data_Science"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Data Science"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Health_And_Lifestyle}
                  size={"small"}
                  color="primary"
                  name="Health_And_Lifestyle"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Health & Lifestyle"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Science_And_Engineering}
                  size={"small"}
                  color="primary"
                  name="Science_And_Engineering"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Science & Engineering"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Social_Studies}
                  size={"small"}
                  color="primary"
                  name="Social_Studies"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Social Studies"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Programming}
                  size={"small"}
                  color="primary"
                  name="Programming"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Programming"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Math}
                  size={"small"}
                  color="primary"
                  name="Math"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Math"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Other}
                  size={"small"}
                  color="primary"
                  name="Other"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Other"
            />
          </FormGroup>
        </Grid>

        <Grid xs={12}>
          <Button size={"small"} onClick={fireFilter} className="filterBtn">
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
    //   </Fade>
    // </Modal>
  );
}
// filter Box
function FilterOfSmallSize({ filter, setFilter, meta, setMeta }) {
  function handleFilter(e) {
    tracker("Course Action", "Selecting filter", e.target.name, "filter");
    setFilter((old) => ({ ...old, [e.target.name]: e.target.checked }));
  }

  function fireFilter(e) {
    setMeta((old) => ({
      ...old,
      fireFilter: !old.fireFilter,
      hasMore: true,
      offset: {
        Udemy: 0,
        edX: 0,
       "Future-Learn": 0,
        Coursera: 0,
      },
    }));
    setFilter((old) => ({ ...old, open: false, search: "" }));
  }

  return (
    <Drawer
      className="hamFilter"
      anchor={"right"}
      open={filter.open}
      onClose={() => setFilter((old) => ({ ...old, open: !old.open }))}
    >
      <img src={pink} className="cardImage" alt="design" />
      <Box className="hamFilterInner">
        <Box
          sx={{ display: "flex", alignSelf: "end", padding: "3% 10% 0% 0%" }}
        >
          <IconButton
            onClick={() => setFilter((old) => ({ ...old, open: false }))}
          >
            <Close></Close>
          </IconButton>
        </Box>
        {/* // Provider */}
        <Box className="filterItems">
          <Typography className="filterTitle">Provider</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Udemy}
                  size={"small"}
                  color="primary"
                  name="Udemy"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Udemy"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.edX}
                  size={"small"}
                  color="primary"
                  name="edX"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="edX"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter["Future-Learn"]}
                  size={"small"}
                  name="Future-Learn"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Future Learn"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Coursera}
                  size={"small"}
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  name="Coursera"
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Coursera"
            />
          </FormGroup>
        </Box>
        {/* Fees  */}
        <Box className="filterItems">
          <Typography className="filterTitle">Fees</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Free}
                  size={"small"}
                  color="primary"
                  name="Free"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Free"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Paid}
                  size={"small"}
                  color="primary"
                  name="Paid"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Paid"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Subscription}
                  size={"small"}
                  name="Subscription"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Provider Subscription"
            />
          </FormGroup>
        </Box>
        {/* Start Date  */}
        <Box className="filterItems">
          <Typography className="filterTitle">Start Date</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.withIn30}
                  size={"small"}
                  color="primary"
                  name="withIn30"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Start With In 30 Days"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.after30}
                  size={"small"}
                  color="primary"
                  name="after30"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Start After 30 Days"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Flexible}
                  size={"small"}
                  name="Flexible"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Flexible"
            />
          </FormGroup>
        </Box>
        {/* Subjects  */}
        <Box className="filterItems">
          <Typography className="filterTitle">Subjects</Typography>
          <FormGroup>
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Computer_Science}
                  size={"small"}
                  color="primary"
                  name="Computer_Science"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Computer Science"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Arts_And_Design}
                  size={"small"}
                  color="primary"
                  name="Arts_And_Design"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Arts & Design"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Business}
                  size={"small"}
                  color="primary"
                  name="Business"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Business"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Data_Science}
                  size={"small"}
                  color="primary"
                  name="Data_Science"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Data Science"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Health_And_Lifestyle}
                  size={"small"}
                  color="primary"
                  name="Health_And_Lifestyle"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Health & Lifestyle"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Science_And_Engineering}
                  size={"small"}
                  color="primary"
                  name="Science_And_Engineering"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Science & Engineering"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Social_Studies}
                  size={"small"}
                  color="primary"
                  name="Social_Studies"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Social Studies"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Programming}
                  size={"small"}
                  color="primary"
                  name="Programming"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Programming"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Math}
                  size={"small"}
                  color="primary"
                  name="Math"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Math"
            />
            <FormControlLabel
              className="filterLable"
              control={
                <Checkbox
                  checked={filter.Other}
                  size={"small"}
                  color="primary"
                  name="Other"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<AddBoxIcon />}
                  onChange={handleFilter}
                />
              }
              label="Other"
            />
          </FormGroup>
        </Box>

        <Box>
          <Button size={"small"} onClick={fireFilter} className="hamFilterBtn">
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
    //   </Fade>
    // </Modal>
  );
}

export default CourseList;
