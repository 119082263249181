import { Box, Divider, Grid, Typography, Link as A } from "@mui/material";
import React, { useEffect, useState } from "react";

// icons
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import yt from "../../assets/images/youtube.png";
import linkedIn from "../../assets/images/linkedin.png";
import twitter from "../../assets/images/twitter.png";

//css
import "../../assets/css/footer.css";
import { Link } from "react-router-dom";
import { getRecentPost } from "../services/services";

export default function Footer() {
  const [recentPost, setRecentPost] = useState([]);

  useEffect(() => {
    fetchRecentBlogs();
  }, []);

  async function fetchRecentBlogs(params) {
    try {
      let response = await getRecentPost(5);
      if (response.status === 200) setRecentPost([...response.data.post]);
      else setRecentPost([]);
    } catch (error) {
      console.log("Error in Footer Recent Blogs", error);
    }
  }
  const allObj = {
    Subjects: [
      { link: "/listing/Computer-Science/true", title: "Computer Science" },
      { link: "/listing/Marketing/true", title: "Marketing" },
      { link: "/listing/Business/true", title: "Business" },
      { link: "/listing/Health-&-Lifestyle/true", title: "Health & Lifestyle" },
      { link: "/listing/Science/true", title: "Science" },
      { link: "/listing/Social-Studies/true", title: "Social Studies" },
      { link: "/listing/Programming/true", title: "Programming" },
      { link: "/listing/Math/true", title: "Math" },
      { link: "/listing/Art-&-Design/true", title: "Art & Design" },
    ],
    Provider: [
      { link: "/listing/Udemy/show", title: "Udemy" },
      { link: "/listing/Coursera/show", title: "Coursera" },
      { link: "/listing/edX/show", title: "edX" },
      { link: "/listing/Future-Learn/show", title: "Future Learn" },
    ],
    University: [
      { link: "/listing", title: "Harvard" },
      { link: "/listing", title: "Rice University" },
      { link: "/listing", title: "IIT Roorkee" },
      { link: "/listing", title: "Stanford University" },
      { link: "/listing", title: "Columbia University" },
      { link: "/listing", title: "Cambridge University" },
    ],
    Institution: [
      { link: "listing", title: "Google" },
      { link: "listing", title: "Microsoft" },
      { link: "listing", title: "IBM" },
      { link: "listing", title: "Amazon" },
      { link: "listing", title: "Salesforce" },
    ],
    "Top Courses": [
      { link: "listing", title: "Top Digital Marketing Courses" },
      { link: "listing", title: "Top Professional Courses" },
      { link: "listing", title: "Top Trending Courses In India" },
      { link: "listing", title: "Free Chat GPT Courses" },
      { link: "listing", title: "Free Artificial Intelligence Courses" },
      { link: "listing", title: "Best Cyber Security Courses" },
    ],
    "Latest Blogs": [
      { link: "", title: "Be an AI Expert with Free AI Course" },
      {
        link: "",
        title:
          "India Leads Surge in Demand for GenAI Courses, Reports Coursera",
      },
      { link: "", title: "Free Online Courses for Advanced Concepts" },
      {
        link: "",
        title: "STEM Careers of the Future: What to Expect in the Next Decade",
      },
      { link: "", title: "How to Choose the Right Online Course Platform" },
      { link: "", title: "Free Courses & Certifications" },
      { link: "", title: "Free Udemy Courses" },
      { link: "", title: "Free Coursera CoursesFree Edx Courses" },
      { link: "", title: "Free Future Learn Courses" },
    ],
  };
  return (
    <>
      <Grid container className="footerContainer">
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">Browser By Subject </Typography>
          {allObj.Subjects.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">
            Browser By Provider{" "}
          </Typography>
          {allObj.Provider.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">
            Browser By University{" "}
          </Typography>
          {allObj.University.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">
            Browser By Institution{" "}
          </Typography>
          {allObj.Institution.map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">Top Courses </Typography>
          {allObj["Top Courses"].map((row, index) => (
            <Typography
              variant="caption"
              component={Link}
              to={row.link}
              key={index}
            >
              {row.title}
            </Typography>
          ))}
        </Grid>
        <Grid xs={12} md={3} className="footerItem">
          <Typography className="footerHeading">Latest Blogs </Typography>
          {recentPost.length !== 0 &&
            recentPost.map((row, index) => (
              <Typography
                variant="caption"
                component={Link}
                to={`/blog-details/${row.slug}`}
                key={index}
              >
                {row.title}
              </Typography>
            ))}
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">About Classbazaar </Typography>
          <Typography variant="caption">
            Class Bazaar is a search engine and reviews website for massive open
            online courses (MOOCs). Class Bazaar aggregates thousands of courses
            from multiple providers so that you find the best courses on almost
            any topic or subject.
          </Typography>
        </Grid>
        <Grid xs={12} md={2} className="footerItem">
          <Typography className="footerHeading">Information </Typography>
          <Typography
            sx={{ fontWeight: 700 }}
            variant={"caption"}
            to={"/blogs"}
            component={Link}
          >
            Blog
          </Typography>
          <Typography variant="caption">info@classbazaar.com</Typography>
          <Typography variant="caption">
            522, Zest Business Spaces, MG Road, Ghatkopar East, Mumbai 400077{" "}
          </Typography>
          <Box className="social_icons">
            <Box
              component={A}
              target="_blank"
              href={"https://www.facebook.com/classbazaar/"}
            >
              <img src={facebook} alt="social_icon" />
            </Box>
            <Box
              component={A}
              target="_blank"
              href={"https://www.instagram.com/classbazaar/?hl=en"}
            >
              <img src={instagram} alt="social_icon" />
            </Box>
            <Box
              component={A}
              target="_blank"
              href={"https://x.com/ClassBazaar"}
            >
              <img src={twitter} alt="social_icon" />
            </Box>
            <Box
              component={A}
              target="_blank"
              href={"https://www.linkedin.com/company/class-bazaar/"}
            >
              <img
                src={linkedIn}
                alt="social_icon"
                style={{ width: "40px", height: "40px" }}
              />
            </Box>
            <Box
              component={A}
              target="_blank"
              href={"https://www.youtube.com/@classbazaarco"}
            >
              <img
                src={yt}
                alt="social_icon"
                style={{ width: "45px", height: "45px" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider className={"divider"}></Divider>
      {/* // copywriter */}
      <Box className="copywriter">
        <Typography variant="caption">Class Bazaar © 2024</Typography>
      </Box>
      {/* // copywrite ends */}
    </>
  );
}
