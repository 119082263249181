import axios from "axios";
import { API, MIIT_base_URI } from "../../config";

export async function getCourses(data, signal) {
  console.log("data >>", data);
    // Convert data to a JSON string
    data = JSON.stringify(data);

    // Encode the data to prevent special characters like & from being altered
    const encodedData = encodeURIComponent(data);
  var url = `${API}/api/v3/courses?filter=${encodedData}`;
  return await axios.get(url, { signal });
}

export async function getCoursesDetails({ title, provider, index_no }) {
  var url = `${API}/api/course?title=${title}&provider=${provider}&index_no=${index_no}`;
  return await axios.get(url);
}

export async function setMIITSession({ email }) {
  console.log(email)
  var url = `${MIIT_base_URI}/User/getSession`;
  return await axios.post(
    url,
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  );
}
export const newLogin = async (data) => {
  console.log(data);
  return await axios.post(API + "/api/loginJWT", data);
};

export const getUserDetails = async (data)=>{
  return await axios.get(API + `/api/getUser?email_address=${data}`);
}

export const verifyToken = async (token) => {
  // console.log(data);
  return await axios.post(API + "/api/verifyToken", token);
};

//  Apis for resume and Carieer Page

export const submitResume = async (data) => {
  // console.log(data)
  return await axios.post(`${API}/api/meetUp`, data);
};

//  Apis for resume and Carieer Page

export const verifyEmail = async (data) => {
  console.log(data);
  return await axios.post(`${API}/api/verificationMail`, data);
};

// Welcome email APIs
export const welcome = async (data) => {
  console.log(data);
  return await axios.get(`${API}/api/welcome?email_address=${data}`);
};

// Forgot password
export const resetPassword = async (data) => {
  return await axios.get(`${API}/api/reset?email_address=${data.email}`);
};
// Forgot password
export const updatePassword = async (data) => {
  return await axios.post(`${API}/api/resetPassword`, data);
};

// search
export const search = async (search, signal) => {
  let response = await axios.get(`${API}/api/search?search=${search}`, {
    signal,
  });
  return response;
};

export const newRegister = async (request) => {
  console.log(request);
  return await axios.post(API + "/api/newregistration", request);
};

export const meetUp = async (request) => {
  // console.log(request);
  return await axios.post(API + "/api/meetUp", request);
};
export const contact = async (request) => {
  console.log(request);
  return await axios.post(API + "/api/contact", request);
};
export const search_track = async (request) => {
  return await axios.post(API + "/api/search_track", request);
};
export const card_track = async (request) => {
  return await axios.post(API + "/api/card_track", request);
};
export const enroll_track = async (request) => {
  return await axios.post(API + "/api/enroll_track", request);
};
export const path_track = async (request) => {
  return await axios.post(API + "/api/path_track", request);
};


// get CLP options 

export const setCLPOptions = async (email) => {
  return await axios.get(API + `/api/setCLPOptions?email_address=${email}`);
};

export const saveCLPOptions = async (data) => {
  return await axios.post(API + `/api/saveCLPOptions`,data);
};

export const getClpDetails = async (data) => {
  return await axios.get(API + `/api/getClpDetails?clp_id=${data}`);
};

export const blogCard = async (page) => {
  return await axios.get(API + `/api/blog-card?page=${page}`);
};

export const getBlogDetails = async (data) => {
  return await axios.get(API + `/api/get-blog-details?slug=${data}`);
};

export const getRecentPost = async (data) => {
  return await axios.get(API + `/api/list-recent-post?limit=${data}`);
};

export const getTagName = async (data) => {
  return await axios.get(API + `/api/list-tag`);
};

export const searchByTag = async (data) => {
  let {uuid, tag} = data;
  return await axios.get(API + `/api/search-by-tag?uuid=${uuid}&tag=${tag}`);
};