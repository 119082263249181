import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import defaultBlogImg from "../../assets/images/defaultBlog.png";

export default function BlogCard({ data }) {
  return (
    <Box
    className="card-box"
    component={Link}
      to={`/blog-details/${data.slug}`}
    >
      {/* image section */}
      {/* // 1200*600 */}
      <Box className="card-img-container">
        <ImageWithFallback src={data.card_image} alt="car_image" />
      </Box>
      {/* description */}
      <Box className="card-description">
        <Typography
          className="card-clip-text-1"
          variant="h5"
          sx={{ fontWeight: 500 }}
        >
          {data.title}
        </Typography>
        <Typography
          className="card-clip-text-3"
          sx={{ color: "gray" }}
          variant="subtitle1"
        >
          {data.description || "I am a default description"}
        </Typography>
      </Box>
      {/* button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          justifySelf: "end",
          alignItems: "center",
          padding: "5px 10px",
        }}
      >
        <Typography
          component={Link}
          to={`/blog-details/${data.slug}`}
          variant="caption"
          color={"primary"}
        >
          Read More
        </Typography>
        <Typography variant="caption">{data.author}</Typography>
      </Box>
    </Box>
  );
}

const ImageWithFallback = ({ src, alt }) => {
  return (
    <img
      style={{ width: "100%", height: "100%" }}
      src={src}
      alt={alt}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop
        e.target.src = defaultBlogImg;
      }}
    />
  );
};
